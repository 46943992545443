import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import operationSignatures from '../_constants/operationSignatures.json';
import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { FEATURE_FLAGS, FeatureFlagService, TARGETS } from '@marriott/mi-feature-flag-utils';
import { DataLayerWrapper, NEXT_PUBLIC_ENV_KEYS } from '@marriott/mi-groups-components';
import { brandSpecificUrls } from '../renderer.config';

function PageRenderer(props) {
  const router = useRouter();
  return (
    <DataLayerWrapper {...props}>
      <PageRendererComponent {...props} key={router.asPath} />
    </DataLayerWrapper>
  );
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const groupsSpecificData = async (context: any) => {
  // to get the dtt flag from Harness FeatureFlagService
  const featureFlagApiKey = process.env['HARNESS_API_KEY'];
  const featureFlagService = new FeatureFlagService(featureFlagApiKey || '');
  const dttFlags: { [key in FEATURE_FLAGS]?: string | boolean | Record<string, unknown> } =
    await featureFlagService.getAllFlags({
      target: TARGETS.APP.GROUPS_RENDERER_DTT,
    });

  const isDttEnabledFromHarness = dttFlags[FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP];

  if (isDttEnabledFromHarness) {
    context?.res?.setHeader('Set-Cookie', `dtt=${isDttEnabledFromHarness}; Path=/; Secure; SameSite=None`);
  }

  return {
    operationSignatures,
    nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
    selector: brandSpecificUrls.includes(context.query.page.join('/')) ? context.query.brandCode : null,
    dttFlags,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(groupsSpecificData);
export default PageRenderer;
